/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// GENERAL
// ---------------

$color1:              #1faee4; // blue
$color2:              #134d8d; // dark blue
$color3:              #1abc9c; // green
$color4:              #e3f2f0; // light green
$color5:              #ebf4fb; // light blue

// TYPOGRAPHY
// ---------------

$textColor:           #333;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "averta", Helvetica, Arial, sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $color1;

$hrColor:             #DDD;
